"use strict"

import $ from 'jquery'
import 'popper.js'
import 'bootstrap'
import '../scss/site.scss'

window.jQuery = $
window.$ = $

function validateEmail(email) {
	var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	return re.test(email);
}

$(function() {
	// Add the active class in the main menu
	$('#mainmenu a[href="' + location.pathname + '"]').parent().addClass('active')

	// Initialize Tooltips
	$(function () {
		$('[data-toggle="tooltip"]').tooltip()
	})

	$('.megamenu').hover(
		function() {
			$('.dropdown-menu', this).fadeIn('fast')
		},
		function() {
			$('.dropdown-menu', this).fadeOut('fast')
		}
	)
	
	$('#main-search-btn').on('click', function() {
		$('#main-search-input').animate({width: 'toggle'})
	})
	
	
	// Back to top button
	$(window).scroll(function() {
		if ($(this).scrollTop() > 100) {
			$('.back-to-top').fadeIn('slow')
		} else {
			$('.back-to-top').fadeOut('slow')
		}
	})

	$('.back-to-top').click(function() {
		$('html, body').animate({scrollTop : 0}, 1000)
		return false
	});
	
	$('#subscribeForm').on('submit', function(e) {
		e.preventDefault()
		var email = $('#subscribeForm input').val().trim().toLowerCase()

		if (validateEmail(email)) {
			$(this).removeClass('error')
			$(this).addClass('saving')

			$.ajax({
				url: '/api/subscription/' + encodeURI(email),
				method: 'put',
				context: this
			}).done(function(result) {
				console.log($(this))
				$(this).removeClass('saving')
				$(this).addClass('success')
				$('#subscribeForm [type=email]').val('')
				setTimeout(function(){$('#subscribeForm').removeClass('success')}, 2000)
			}).fail(function(){
				$(this).removeClass('saving')
				$(this).addClass('error')
			})

		} else {
			$('#subscribeForm').addClass('error')
		}
	})
})
